/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import Logo from "../../assets/rscm-topMan.png"
import { Button, Modal, Menu, Popover, message, Dropdown } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import Logos from "../../assets/rscm-top.png"
import { ROLE_MEUS_LIST, ROLE_CODE } from "../../store/constants"
import { useDispatch, useSelector } from "react-redux"
import { navConfig, deepLoopFloat } from "./nav.config"
import "./index.scss"
import { CheckOutlined } from "@ant-design/icons"
import gsap from "gsap"
import _ from "lodash"
import classNames from "classnames"
import OrgChange from "./components/orgChange"
import type { MenuProps } from "antd"
import { saveKey } from "./../../components/SkTable/content"
type type_props = {
  config: {
    value: string
  }
}
const TopNavbar: React.FC<type_props> = ({ config }) => {
  const nav = useNavigate()
  const location = useLocation()
  const roles: any = useSelector((state: any) => state.codeList)
  const dispatch = useDispatch<any>()
  let OrgList = JSON.parse(sessionStorage.getItem("ORGCHANGE") || "[]")
  const locationObject = localStorage.getItem("operation_authorization") || "{}"
  const AccCodeLost: any = sessionStorage.getItem("ACCESS_KEYS")
  const [codeList, setCodeList] = useState<any>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const currentTenantId = sessionStorage.getItem("tenantId")
  const ltdName = sessionStorage.getItem("tenantName")
  const navigate = useNavigate()
  useEffect(() => {
    if (
      !JSON.parse(locationObject).access_token ||
      JSON.parse(locationObject).access_token === undefined
    ) {
      // navigate('/login')
    } else {
      dispatch({
        type: ROLE_CODE,
        payload: {
          codeList: JSON.parse(locationObject)?.functionCode?.split(","),
          roleCodes: JSON.parse(locationObject)?.roleCodes?.split(","),
        },
      })
    }
  }, [locationObject])

  const getLists = useMemo(() => {
    dispatch({
      type: ROLE_MEUS_LIST,
      payload: {
        meunList: codeList,
      },
    })
    return codeList
  }, [roles, codeList])
  useEffect(() => {
    let res: any = deepLoopFloat(navConfig, AccCodeLost)
    console.log(res, "res")
    if (res.length === 0) {
      navigate("/login")
    } else {
      setCodeList(res)
    }
    const endIndex = location.pathname.lastIndexOf("/")
    const str = location.pathname.substring(0, endIndex + 1)
    if ("/business/ticket/InvoicingTask/info/" !== str) {
      localStorage.removeItem("cacheInformation")
      localStorage.removeItem("invoces")
    }
  }, [location])

  // 退出登录
  const logout = () => {
    Modal.confirm({
      title: "温馨提示 🧡",
      icon: <ExclamationCircleOutlined />,
      content: "是否确认退出登录？",
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        sessionStorage.clear()
        localStorage.clear()
        message.success("退出登录成功！")
        navigate("/login")
      },
    })
  }
  const findLastLayerPath = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.path
    }
    return findLastLayerPath(node.children[0])
  }
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div style={{ textAlign: "left", width: 60 }} className="confirm">
          <span className="dropdown-item" onClick={logout}>
            退出登录
          </span>
        </div>
      ),
    },
  ]
  return (
    <header className="top-navbar" style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div className="logo">
          <img src={Logos} alt="logo" />
          <p>运营中心</p>
        </div>
        <div className="nav_list_content">
          {getLists &&
            getLists?.map((item: any) => {
              return (
                <div
                  className={`navbar-link ${config?.value === item.value ? "active" : null}`}
                  key={item.value}
                >
                  <Link
                    to={findLastLayerPath(item)}
                    onClick={() => {
                      sessionStorage.removeItem(saveKey())
                    }}
                  >
                    {item.name}
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
      {/* 用户信息 */}
      <div className="user_info">
        <OrgChange />
        <Dropdown menu={{ items }} placement="bottom" arrow>
          <div>
            <img src={Logo} alt="logo" className="user_logo" />
            <span className="user_name">{JSON.parse(locationObject)?.userName}</span>
          </div>
        </Dropdown>
      </div>
    </header>
  )
}

export default TopNavbar
